export default [
  {
    element: '.mode-switcher',
    popover: {
      title: '返回普通版',
      description: '点击这里退出老年版',
      position: 'bottom-right'
    }
  },
  {
    element: '.footer-voice',
    popover: {
      title: '语音输入',
      description: '点击这里切换到语音提问',
      position: 'top-left'
    }
  },
  {
    element: '.footer-voice2',
    popover: {
      title: '文字输入',
      description: '点击这里切换到文字提问',
      position: 'top-left'
    }
  },
  {
    element: '.footer-textarea-wrapper',
    popover: {
      title: '输入框',
      description: '点击输入您想要咨询的问题',
      position: 'top-left'
    }
  },
  {
    element: '.buttonSh',
    popover: {
      title: '长按说话',
      description: '长按这里语音提问',
      position: 'top-left'
    }
  },
  {
    element: '.footer-send-btn ',
    popover: {
      title: '发送按钮',
      description: '点击这里进行提问',
      position: 'top-right'
    }
  }
]
