<template>
  <div class="chat-list-panel" ref="scroller">
    <!-- 消息列表 -->
    <div class="app-content">
      <div class="chat-list" :style="style">
        <chat-bubble
          v-for="(item, index) in messages"
          :key="index"
          :index="index"
          :message="item"
          ref="chatBubble"
        ></chat-bubble>
      </div>
    </div>

    <layer-guide ref="guide" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Guide from '@/views/Guide'
import { throttle } from 'lodash'
import ChatBubble from '@/components/ChatBubble'
import './careModeStyle.scss'

export default {
  name: 'ChatView',
  data () {
    return {}
  },
  computed: {
    ...mapState(['ui', 'freshNeed']),
    ...mapGetters(['exam', 'messages']),
    style () {
      console.log(this.level, this.examId)
      return {
        paddingBottom: this.exam ? '50px' : '10px'
      }
    }
  },
  watch: {
    messages () {
      this.scrollToEnd()
      console.log('message updated')
    },
    ui () {
      this.scrollToEnd()
      console.log('ui updated')
    },
    freshNeed () {
      this.$store.commit('updateFreshNeed', false)
      this.scrollToEnd(true)
      console.log('updateFreshNeed updated')
    }
  },
  components: {
    [ChatBubble.name]: ChatBubble,
    [Guide.name]: Guide
  },
  methods: {
    scrollToEnd: (function () {
      let prevIndex = null
      return throttle(
        function (toBottom = false) {
          // 延迟到 dom 渲染完成之后，执行滚动操作
          setTimeout(() => {
            console.log('=========================')
            // 消息的列表自下而上的查找返回结果
            // 首次进入不需要做滚动
            if (prevIndex !== null) {
              if (toBottom) {
                // 通过组件元素获取到他需要滚动的距离
                const el = this.$refs.chatBubble[this.messages.length - 1].$el
                const scroller = this.$refs.scroller
                scroller.scrollTo(
                  0,
                  el.offsetTop + el.offsetHeight + scroller.offsetHeight
                )
              } else {
                let isFromUserFound = false
                for (let i = this.messages.length; i >= prevIndex; i--) {
                  const message = this.messages[i]
                  // 找到用户输入的问题部分
                  if (message && message.from === 2) {
                    // 通过组件元素获取到他需要滚动的距离
                    const el = this.$refs.chatBubble[i].$el
                    const scroller = this.$refs.scroller
                    scroller.scrollTo(
                      0,
                      // el.offsetTop + (scroller.offsetHeight * 2) / 3
                      el.offsetTop - (scroller.offsetHeight * 1) / 5
                    )

                    prevIndex = i + 1
                    isFromUserFound = true
                    break
                  }
                }
                if (!isFromUserFound) {
                  const index = this.messages.length - 1
                  const el = this.$refs.chatBubble[index].$el
                  const scroller = this.$refs.scroller
                  prevIndex = this.messages.length - 1
                  scroller.scrollTo(
                    0,
                    // el.offsetTop + (scroller.offsetHeight * 2) / 3
                    el.offsetTop - (scroller.offsetHeight * 1) / 5
                  )
                }
              }
            } else {
              prevIndex = this.messages.length - 1
            }
          }, 80)
        },
        300,
        {
          leading: false,
          trailing: true
        }
      )
    })()
  },
  created () {
    this.$store.commit('updateLayout', {
      footerHeight: 50
    })
  },
  mounted () {
    if (this.$store.state.isVoiceInitial) {
      this.$refs.guide.start(() => {
        this.$store.dispatch('initApp')
      })
    } else {
      this.evtBus.$once('envReady', () => {
        this.$refs.guide.start(() => {
          this.$store.dispatch('initApp')
        })
      })
    }
  },
  deactivated () {},
  activated () {
    if (this.messages.length > 0) {
      this.scrollToEnd(true)
      console.log('page actived')
      this.$store.commit('updateLayout', {
        footerHeight: 50
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-list-panel {
  box-sizing: border-box;
  background-color: $chat-bgc;
  height: 100%;
  margin: 0 10px;
  padding: 10px 25px;
  border-radius: 5px;
  overflow: auto;
  .app-content {
    height: 100%;
  }
}
</style>
