<template>
  <div></div>
</template>

<script>
import Driver from 'driver.js'
import 'driver.js/dist/driver.min.css'
import options from './options'

export default {
  name: 'LayerGuide',
  data () {
    return {
      driver: null,
      callback: null
    }
  },
  methods: {
    start (callback) {
      this.callback = callback
      const startStep = () => {
        this.driver.defineSteps(options)
        setTimeout(() => {
          this.driver.start()
        }, 10)
      }
      if (this.driver) {
        startStep()
      } else {
        this.$watch('driver', startStep)
      }
    }
  },
  mounted () {
    const self = this
    this.driver = new Driver({
      className: 'guideWrap', // 新手指引的容器名字，标号1的地方
      padding: 5,
      animate: true,
      allowClose: false,
      keyboardControl: true,
      showButtons: false,
      stageBackground: 'rgba(255, 255, 255)',
      overlayClickNext: true, // 点击是否允许蒙层关闭，
      onHighlightStarted: (Element) => {
        const input = Element.node.querySelector('textarea')

        input && input.setAttribute('readonly', true)
      },
      onNext: (Element) => {
        const input = Element.node.querySelector('textarea')

        input && input.removeAttribute('readonly')
      },
      onReset () {
        self.callback && self.callback()
      }
    })
  }
}
</script>

<style lang="scss">
.guideWrap {
  .driver-popover-title {
    display: none !important;
  }
  .driver-popover-description {
    font-size: 0.18rem !important;
  }
  &#driver-popover-item {
    max-width: unset;
    min-width: unset;
  }
}
.driver-highlighted-element {
  border-radius: 3px;
}
</style>
